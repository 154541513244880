<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div> <h1> <strong>FICHE D'ENQUETE SUR LES VISITEURS INTERNATIONAUX - FRONTIERES <br> TOURISME EMETTEURS</strong> </h1>  </div>
        </b-col>
        <!-- <b-col md="12">
          <div>
            <small>(Agence  de location de véhicules sans opérateur)</small>
          </div>
        </b-col> -->
      </b-row>
    </div>

    <!-- renseignment exploitation du mois -->
    <!-- <div class="title-background mt-2">
      <b-row class="text-center">
        <b-col
          md="12"
          class="text-center text-dark"
        >
          <strong> IV- RENSEIGNEMENTS SUR L'EXPLOITATION DU MOIS</strong>
        </b-col>
      </b-row>
    </div> -->

    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row>
          <!-- pays résidence -->
          <b-col
            md="12"
            class="mb-1"
          >
            1 Dans quel pays vivez vous ?: <strong>{{ validOutput.paysResidence }}</strong>
          </b-col>
          <!-- pays visités -->
          <b-col
            md="12"
            class="mb-1"
          >
            2 Quel(s) pays avez-vous visité(s) ? : <strong>{{ validOutput.paysVisites }}</strong>
          </b-col>

          <!-- nationalite -->
          <b-col
            md="12"
            class="mb-1"
          >
            3.	De quelle nationalité êtes-vous ? : <strong>{{ validOutput.nationalite }}</strong>
          </b-col>
          <!-- date départ bénin -->
          <b-col
            md="12"
            class="mb-1"
          >
            4.	A quelle date avez-vous quitté le Bénin ? <strong>{{ validOutput.dateDepartBenin }}</strong>
          </b-col>
          <!-- date retour bénin -->
          <b-col
            md="12"
            class="mb-1"
          >
            5.	A quelle date êtes-vous retournés au Bénin ?  <strong>{{ validOutput.dateRetourtBenin }}</strong>
          </b-col>
          <!-- compte voyageurs -->
          <b-col
            md="12"
            class="mb-1"
          >
            6.	Avec combien de personnes, y compris vous-même, voyagez-vous en partageant les dépenses ? <strong>{{ validOutput.coVoyageursAdultes }}</strong> personnes dont <strong>{{ validOutput.coVoyageursEnfants }}</strong> enfants de  moins de 18 ans.
          </b-col>
          <hr>
          <!-- motif de votre visite -->
          <b-col
            md="12"
            class="mb-1"
          >
            7.	Quel a été le motif de votre visite à l’étranger ?  <strong v-for="pays in validOutput.motifVoyage" :key="pays" >{{ pays }}</strong> 
          </b-col>
          <!-- activités pratiquée -->
          <b-col
            md="12"
            class="mb-1"
          >
            8.	Parmi les activités suivantes, lesquelles avez-vous pratiquées pendant votre séjour à l’étranger? <br> 
            <strong class="mx-1" v-for="(item, index) in validOutput.activiteEtranger"  >{{ item}}</strong>
          </b-col>
          <!-- nombre nuits -->
          <b-col
            md="12"
            class="mb-1"
          >
            9.	Indiquez le nombre de nuits passées par type d’hébergement pendant votre séjour à l’étranger <br>
            <strong class="mx-1" v-for="(item, index) in validOutput.nombreNuitParType"  >
              {{ item.type}} - {{ item.nombreNuit}}  <br>
            </strong>
            <!-- <strong> {{validOutput.nombreNuitParType}} </strong> -->
          </b-col>

          <!-- moyen transport départ bénin -->
          <b-col
            md="12"
            class="mb-1"
          >
            10.	Par quel principal moyen de transport avez-vous quitté le Bénin ?  
            <strong> {{validOutput.moyenTransportDepartBenin}} </strong>
          </b-col>
          <!-- départ par avion -->
          <b-col
            md="12"
            class="mb-1"  v-if="validOutput.moyenTransportDepartBenin == 'Avion'"
          >
            11.	Si vous avez quitté par avion, veuillez préciser s’il s’agit :
            <strong> {{validOutput.departBeninParAvion.type}} </strong> <strong> {{validOutput.departBeninParAvion.compagnie}} </strong> 
          </b-col>
          <!-- départ par bus -->
          <b-col
            md="12"
            class="mb-1"  v-if="validOutput.moyenTransportDepartBenin == 'Autobus'"
          >
            12.	Si vous avez quitté le Bénin en autobus, veuillez préciser :
            <strong> {{validOutput.departBeninParAutobus.compagnie}} </strong> <strong> {{validOutput.departBeninParAutobus.residenceCompagnie}} </strong> 
          </b-col>
          <!-- moyen transport utilisé a étranger -->
          <b-col
            md="12"
            class="mb-1"
          >
            13.	Quels moyens  de transport avez-vous utilisé à l’étranger ? <strong> {{validOutput.moyenTransportEtranger}} </strong>
          </b-col>
          <!-- dépenses voyage avant depart -->
          <b-col
            md="12"
            class="mb-1" 
          >
            14.	Indiquez combien vous avez payé en tout pour ce voyage avant de quitter le Bénin: <br>
            <strong>{{validOutput.depensesAvantDepart.type }}</strong>
            
            <span v-if="validOutput.depensesAvantDepart.type != 'Je n\'ai rien payé avant mon arrivé'">
              ayant coûté <strong> {{validOutput.depensesAvantDepart.coutTotal}} {{validOutput.depensesAvantDepart.devise}} </strong> <br>
              <!-- <span> <strong> {{ validOutput.depensesAvantDepart.coutTotal }} {{ validOutput.depensesAvantDepart.devise }} </strong> </span> -->
              <strong class="mx-1" v-for="(item, index) in validOutput.depensesAvantDepart.options">
                
                <span v-if="validOutput.depensesAvantDepart.type == 'Voyage à forfait' ">
                  <span v-if="item.etat == true"> <feather-icon icon="CheckIcon"  /> {{item.titre}} <br> </span>
                  <span v-else> {{item.titre}} <br> </span>
                </span>
                <span v-if="validOutput.depensesAvantDepart.type == 'Voyage sans forfait' ">
                  <span v-if="item.etat == true"> <feather-icon icon="CheckIcon"  /> {{item.titre}} - {{item.valeur}} <br> </span>
                  <span v-else class="ml-1"> {{item.titre}} <br> </span>
                </span>

              </strong>
            </span>
          </b-col>

        <!-- </b-row>
        <hr class="invoice-spacing mt-5">
        <b-row>-->

          <hr class="invoice-spacing">

          <!-- dépenses voyage durant sejour -->
          <b-col
            md="12"
            class="mb-1"
          >
            15.	Indiquez combien vous avez payé en tout pour ce voyage pendant votre séjour à l’étranger <br> 
            <strong>{{validOutput.depensesDurantSejour.type }}</strong><br>
            <span v-if="validOutput.depensesDurantSejour.type != 'Je n\'ai rien payé pendant mon séjour' ">                   
              <strong class="mx-1" v-for="(item, index) in validOutput.depensesDurantSejour.options">
                <span >{{item.titre}} - {{item.valeur}} {{item.devise}}<br></span>
              </strong>
            </span>
          </b-col>
          
          <!-- satisfaction -->
          <b-col
            md="12"
            class="mb-1"
          >
            16.	Dans l’ensemble, votre séjour à l’étranger vous a-t-il satisfait ? <strong> {{validOutput.satisfactionSejour}} </strong> 
          </b-col>
          <!-- fréquence voyage -->
          <b-col
            md="12"
            class="mb-1"
          >
            17.	Voyagez vous souvent à l'étranger ?  : <strong> {{validOutput.frequenceVoyage}} </strong> 
          </b-col>
          <!-- sexe -->
          <b-col
            md="12"
            class="mb-1"
          >
            18.	Sexe : <strong> {{validOutput.sexe}} </strong> 
          </b-col>
          <!-- age -->
          <b-col
            md="12"
            class="mb-1"
          >
            19.	Votre age : <strong> {{validOutput.age}} </strong> ans
          </b-col>

        </b-row>
        <!-- <hr> -->
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    vSelect,
    Logo,

  },
  directives: {
    Ripple,
  },
  props: {
    validOutput: Object,
  },
  data() {
    return {
    }
  },

  mounted() {
    // this.validOutput.agrementMinistere = 'non'
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background{
  background-color: #ababab;
}

.tableHeadFontSize{
  font-size: 10px;
}
</style>
